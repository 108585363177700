var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"error-box"},[_c('v-img',{attrs:{"src":require(`@/assets/images/errors/${_vm.code}.png`),"alt":"Изображение ошибки","height":"344","width":"500"}}),_c('div',{staticClass:"d-flex flex-column align-center"},[_c('h1',{staticClass:"font-weight-medium text-h3 mb-4"},[_vm._v(_vm._s(_vm.title))]),_c('p',{class:[
        'font-weight-regular',
        'secondary--text',
        'text--lighten-1',
        'text-h6',
        'text-center',
        'mb-0',
      ]},[_vm._v(" "+_vm._s(_vm.message)+" ")])]),_c('x-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.useRedirectButton),expression:"useRedirectButton"}],attrs:{"text":""},on:{"click":function($event){return _vm.$router.go(-2)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Вернуться на предыдущую страницу ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }